<template>
  <CRow>
    <CModal
        :title="
        submitType === 'Update'
          ? $lang.profile.crud.social_edit
          : $lang.profile.crud.create
      "
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
    >
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{
          err_msg
        }}
      </CAlert>
      <CForm novalidate @submit.prevent="onSubmit">
        <CCardBody>
          <div
              class="form-group"
              :class="{ 'form-group--error': $v.profile.SocialLink.$error }"
          >
            <label class="form__label"
            >{{ $lang.profile.form.SocialLink }}
              <required_span/>
            </label>
            <input
                :maxlength="maxlength.SocialLink"
                :placeholder="$lang.profile.form.SocialLink"
                class="form-control"
                v-model="profile.SocialLink"
            />
            <small
                class="error"
                v-if="
                $v.profile.SocialLink.$error && !$v.profile.SocialLink.required
              "
            >{{ $lang.profile.validation.required.SocialLink }}</small
            >
          </div>

          <div
              class="form-group"
              :class="{ 'form-group--error': $v.profile.platform.$error }"
          >
            <label class="form__label"
            >{{ $lang.profile.form.platform }}
              <required_span/>
            </label>
            <input
                :maxlength="maxlength.platform"
                :placeholder="$lang.profile.form.platform"
                class="form-control"
                v-model="profile.platform"
            />
            <small
                class="error"
                v-if="$v.profile.platform.$error && !$v.profile.platform.required"
            >{{ $lang.profile.validation.required.platform }}</small
            >
          </div>

          <div class="form-group" :class="{ 'form-group--error': '' }">
            <label class="form__label"
            >{{ $lang.profile.form.isVerified }}
            </label>
            &nbsp;&nbsp;
            <input
                type="checkbox"
                :placeholder="$lang.profile.form.isVerified"
                :value="1"
                @change="changedIsActive"
                v-model="profile.isVerified"
            />
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton
              type="submit"
              size="sm"
              color="primary"
              :disabled="submitted"
          >
            <CIcon name="cil-check-circle"/>
            {{ $lang.buttons.general.crud.submit }}
          </CButton>
        </CCardFooter>
      </CForm>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>

    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.profile.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="
                      exports(self, 'pdf', module, 'SocialLinks', self.editId)
                    "
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <!--        {{ showAlert }}-->
        <!--        <alerts :message="alertMessage" type="success" :v-if="showAlert"/>-->
        <CCardBody>
          <CAlert
              color="success"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >

          <div id="people">
            <v-server-table
                :columns="columns"
                :url="listUrl"
                :options="options"
                ref="myTable"
                @loaded="
                exports(self, 'Excel', module, 'SocialLinks', self.editId)
              "
            >
              <template #actions="data">
                &nbsp;
                <CButton
                    color="success"
                    v-c-tooltip="$lang.buttons.general.crud.edit"
                    v-on:click="editTableRow(data.row.id)"
                    v-if="checkPermission('edit-social-links')"
                >
                  <i class="fas fa-pencil-alt"></i>
                </CButton>
              </template>
            </v-server-table>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {profileLinks} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import VueJsonToCsv from "vue-json-to-csv";
import {Maxlength} from "../../store/maxlength";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "ProfileLinks",
  mixins: [Mixin],
  components: {
    required_span,
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      activePage: 1,
      listUrl: "/users/profiles/social-links/",
      module: profileLinks,
      profile: {
        SocialLink: "",
        platform: "",
        isVerified: "",
      },
      maxlength: {
        SocialLink: Maxlength.profile.SocialLink,
        platform: Maxlength.profile.platform,
      },
      columns: ["platform", "SocialLink", "isVerifiedStatus", "actions"],
      data: [],
      options: {
        headings: {
          platform: this.$lang.profile.table.platform,
          SocialLink: this.$lang.profile.table.SocialLink,
          isVerifiedStatus: this.$lang.profile.table.isVerified,
          //   status: this.$lang.profile.table.isActive,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name", "isVerified"],
        sortable: ["name", "isVerified"],
        filterable: ["name", "isVerified"],
      },
    };
  },

  validations: {
    profile: {
      SocialLink: {
        required,
      },
      platform: {
        required,
      },
    },
  },

  mounted() {
    let self = this;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;

    self.listUrl = this.listUrl + self.editId;

    //self.data = this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.$root.$on("alert", (arg1, arg2) => {
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.profile.isVerified = 1;
      } else {
        self.profile.isVerified = 0;
      }
    },

    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if accepted
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.response.message;
                  self.dismissCountDownS = 10;
                  // self.$router.go()
                }
              });
        }
      });
    },
    createTableRow() {
      this.$router.push({name: "Profile Create"});
    },
    editTableRow(item) {
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      const id = item;
      self.editId = item;
      axios
          .get(this.viewUrlApi(this.module, id), self.model)
          .then(function (response) {
            if (response) {
              const responseData = response.data;
              self.profile.SocialLink = responseData.SocialLink;
              self.profile.platform = responseData.plateform;
              self.profile.isVerified = responseData.isVerified;
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    rowClicked(item) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      axios
          .get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response) {
              self.data = response.data;
            } else {
              self.data = [];
            }
          });
    },

    onSubmit() {
      let self = this;
      this.$v.profile.$touch();
      if (this.$v.profile.$invalid) {
        self.submitted = false; //Enable Butto
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            const postData = {
              socialLink: self.profile.SocialLink,
              plateform: self.profile.platform,
              isVerified: self.profile.isVerified,
            };
            axios
                .post(this.updateUrlweb(this.module, self.editId), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                    self.alertMessage = response.data.response.message;
                    self.dismissCountDownS = 10;
                  } else {
                    self.err_msg = response.data.response.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  self.submitted = false; //Enable Button
                });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            const postData = {
              socialLink: self.profile.SocialLink,
              plateform: self.profile.platform,
              isVerified: self.profile.isVerified,
            };
            axios
                .post(this.createUrlWeb(this.module), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.response.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.response.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  self.submitted = false; //Enable Button
                });
          }, 500);
        }
      }
    },
  },
};
</script>
